.newsletter
    \:global
        table
            width: 100%

        label
            display: block
            margin: 15px 0 10px 0
            font-family: "LotaGrotesqueAlt3"
            font-weight: 300
            font-size: 15px
            line-height: 20px

        input[type="email"],
        input[type="text"]
            width: 100%
            height: 40px
            padding: 10px
            line-height: 40px
            border: 2px solid #0022bf
            color: #0022bf
            font-size: 18px
            font-family: "LotaGrotesqueAlt3"
            max-width: 400px

        h3
            margin-bottom: 15px

        input[type="submit"]
            display: flex
            width: 33%
            margin-top: 25px
            margin-bottom: 50px
            align-items: center
            justify-content: center
            flex-direction: column
            height: 50px
            font-size: 12px
            line-height: 15px
            background: #ff5eff
            border: none
            text-align: center
            color: #fff
            cursor: pointer
            border-radius: 25px
