@import 'sass/theme'
    
.page
    position: relative
    min-height: 100vh
    transition: opacity 0.2s linear
    opacity: 0

    &:global(.in)
        opacity: 1

    &:global(.out)
        position: absolute
        width: 100%
        transition-duration: 0.1s
        z-index: -1
        opacity: 0
    
    > div
        margin: 0 auto
        max-width: 1020px
        width: 100%
        
        @media #{$desktop-big}
            margin-left: 0
            margin-right: auto

    &:global(.map) 
        > div
            margin: 0
            max-width: 100%
            padding-left: 280px

            @media #{$desktop-big}
                padding-left: 0

    &:not(:global(.map))
        > div
            padding-bottom: 40px

    \:global
        .title           
            padding: 34px 0 30px 0px
            font-family: 'impuls'
            font-variant-ligatures: discretionary-ligatures
            font-weight: 600
            color: #00cfb7
            font-size: 31px
            line-height: 30px
            font-weight: 600   

            h2
                font-weight: inherit
            
            &.navigation
                display: flex
                justify-content: space-between
                align-items: flex-start

                h2
                    flex: 1 1 auto
                    text-align: center

                a
                    flex: 0 1 auto
                    padding-left: 10px
                    padding-right: 10px
                    cursor: pointer

                    &.previous
                        margin-left: -10px

                    &.next
                        margin-right: -10px

            @media #{$tablet}
                padding-left: 190px

            @media #{$tablet-small} 
                font-size: 16px
                line-height: 20px
                padding-top: 40px
                padding-bottom: 38px

            @media #{$smartphone-big}
                padding-left: 0
                padding-top: 90px 
                padding-bottom: 30px


        .introduction 
            h3 
                margin-bottom: 20px
                font-family: 'impuls'
                font-weight: 200
                font-feature-settings: "dlig"
                font-variant-ligatures: discretionary-ligatures
                font-size: 54px
                line-height: 50px
                color: #00cfb7

                @media #{$smartphone-big}
                    font-size: 32px
                    line-height: 30px

            .rich
                margin-bottom: 40px

            .image
                position: relative
                width: 100%
                max-width: 490px
                margin: 0 auto 20px auto
                text-align: center

                > div
                    background-size: cover