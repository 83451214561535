@import 'sass/theme'
    
.player 
    \:global
        .track 
            height: 3px

            > span 
                display: block
                margin-left: -2px
                margin-right: -2px

            .position 
                display: block
                position: absolute
                margin-top: -15px
                width: 32px
                height: 32px
                background: $color-primary
                border-radius: 50%
                cursor: pointer

                svg 
                    position: absolute
                    left: 8px
                    top: 7px
                    width: 17px
                    height: 17px
                    pointer-events: none

        p
            margin-top: 20px

        
