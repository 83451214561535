@import 'sass/theme'

.teaserlist
    \:global
        .flex
            display: flex
            flex-wrap: wrap
            margin-left: -16px

            > div
                position: relative
                flex: 0 0 33.33%
                padding-left: 16px
                padding-bottom: 10px

                @media #{$tablet-small}
                    flex-basis: 50%

                .image 
                    position: relative
                    padding-bottom: 100%
                   
                    background-position: center
                    background-repeat: no-repeat                  
                    
                h3 
                    margin-top: 3px
                    font-weight: 700
                    font-size: 14px
                    line-height: 20px

    &:global(.colored)
        \:global
            .image
                background-blend-mode: luminosity
                background-size: cover

    &:global:not(.colored)
        \:global
            .borderColorSecondaryHover
                &:hover
                    .image
                        transition-duration: .5s

                .image
                    border: 2px rgba(0,0,0,0) solid
                    transition: 0s ease-out
                    background-size: contain