@import 'sass/theme'

.navigation
    $this: &
    position: fixed
    z-index: 100
    top: 0
    left: 0
    height: 100vh
    width: 0
    font-family: 'impuls'
    font-variant-ligatures: discretionary-ligatures
    font-weight: 600

    @media print
        display: none

    \:global
        .home
            position: relative
            z-index: 11
            display: block
            margin: 32px 0 0 32px
            padding: 0 0 0 15px
            height: 163px
            width: 100px
            pointer-events: all

            @media #{$tablet-small}
                margin-left: 3.9vw
 
            span
                &,
                &:before,
                &:after
                    position: absolute
                    left: 0
                    top: 0
                    display: block
                    width: 32px
                    height: 32px
                    background: $color-primary
                    border-radius: 50%
                    content: ""

                &:before
                    left: 43px
                    
                &:after
                    left: 69px

            svg
                margin-top: 43px
                fill: $color-primary

            &.loading
                span
                    &:before
                        animation: loading-animation 1.2s infinite          

        .menu
            display: none
            position: absolute
            z-index: 2
            left: 150px
            top: 28px
            width: 40px
            height: 40px
            cursor: pointer

            span,
            &:before,
            &:after
                display: block
                position: absolute
                left: 2px
                top: 19px
                width: 36px
                height: 2px
                background: $color-primary
                content: ''

            &:before
                top: 9px

            &:after
                top: 29px

            @media #{$tablet}
                display: block

            @media #{$tablet-small}
                left: 3.9vw
                margin-left: 115px

        a
            position: relative
            font-size: 31px
            line-height: 30px
            pointer-events: all
            
            &.test:hover::after
                display: block
                position: absolute
                z-index: -1
                left: 0px
                right: 0px
                top: 6px
                bottom: 6px
                background: #fff
                content: ""
                opacity: 0.8
                

        .language
            position: absolute
            z-index: 1
            left: 180px
            top: 38px
            font-size: 16px
            line-height: 20px

            @media #{$tablet}
                opacity: 0
                transition: opacity 0.2s ease-out

                &:hover
                    color: #fff !important

                @at-root :global(.show-navigation)#{&}
                    opacity: 1
                    transition-delay: 0.2s
                
        \:global(> div)      
            width: 290px
            height: 100vh
            overflow: hidden
            pointer-events: none
            
            > div
                height: 100vh
                width: 330px
                overflow-y: auto
                pointer-events: none

                > div
                    z-index: 3
                    width: 290px
                    min-height: 100vh
                    padding: 204px 0 32px 32px
                    margin-top: -197px
                    pointer-events: none

                    @media #{$tablet}
                        position: relative
                        z-index: -1
                        left: -300px
                        transition: transform 0.3s ease-out

                    @media #{$tablet-small}
                        padding-left: 3.9vw

                    > ul
                        margin: 15px 0 0 12px

                        @media #{$tablet}
                            .active,
                            a:hover
                                color: #fff !important

                        div
                            overflow: hidden
                            transition: all 0.5s

                            ul
                                padding-bottom: 20px

                            a
                                font-size: 20px
                                line-height: 20px                               

                                span
                                    display: inline-block
                                    width: 37px

                                    @at-root :global(.fr)#{&} 
                                        width: 50px

                                    sup
                                        position: relative
                                        vertical-align: top
                                        font-size: .6em
                                        top: -0.3em
                                        
                        + ul
                            a
                                font-size: 16px
                                line-height: 20px               

    &:global(.show-navigation) 
        > div
            pointer-events: all

            > div
                pointer-events: all

                > div
                    pointer-events: all
                    z-index: 3 
                    transform: translateX(300px)
        
        \:global
            .home:hover
                span
                    &,
                    &:before,
                    &:after
                        background: #fff !important 

                svg 
                    fill: #fff !important                       

    @media #{$tablet}
        \:global
            a.home
                height: 32px
                margin-bottom: 131px

                svg
                    margin-left: -300px
                    transition: transform 0.3s ease-out

        &:global(.show-navigation):not(:global(.home))
            \:global
                a.home
                    height: 163px
                    margin-bottom: 0
                   
                    svg
                        transform: translateX(300px) 

        &:global(.home)
            \:global
                a.home
                    height: 163px
                    margin-bottom: 0

                    svg
                        margin-left: 0
                        transition: none                


@keyframes :global(loading-animation)
    0%  
        left: 43px

    50%
        left: 0

    100%
        left: 43px