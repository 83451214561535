.calendar

    ul
        li
            &:global(.space)
                margin-top: 20px

            > span,
            > a
                display: block
               

                > span
                    display: inline-block
                    vertical-align: top
                  
                > span:nth-child(1)
                    width: 30%
                    padding-right: 16px
                    text-align: right
                    -webkit-font-feature-settings: "lnum" 1
                    -moz-font-feature-settings: "lnum" 1
                    -moz-font-feature-settings: "lnum=1"
                    -ms-font-feature-settings: "lnum" 1
                    -o-font-feature-settings: "lnum" 1
                    font-feature-settings: "lnum" 1

                > span:nth-child(2)
                    width: 70%

                    > span
                        display: block
                        -webkit-background-clip: text
                        -webkit-text-fill-color: transparent
            