@import 'sass/theme'

.loading
    position: absolute
    left: 50%
    top: 50%
    width: 101px
    height: 32px
    transform: translate(-50%, -50%)

    span
        &,
        &:before,
        &:after
            position: absolute
            left: 0
            top: 0
            display: block
            width: 32px
            height: 32px
            background: $color-primary
            border-radius: 50%
            content: ""

        &:before
            left: 43px
            animation: spot2-animation 1.2s infinite

        &:after
            left: 69px

@keyframes spot2-animation
    0%  
       left: 0

    50%
        left: 43px

    100%
        left: 0
