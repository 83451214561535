@import 'sass/theme'

.projectslist 
    \:global   
        \:global(> ul)
            margin-top: -40px

            @media #{$tablet}
                margin-top: -80px

            > li
                padding-top: 40px

                @media #{$tablet}
                    padding-top: 80px

                h3
                    margin-bottom: 20px
                    font-family: 'impuls'
                    font-weight: 200
                    font-feature-settings: "dlig"
                    font-variant-ligatures: discretionary-ligatures
                    font-size: 54px
                    line-height: 50px
                    color: #00cfb7

                    @media #{$smartphone-big}
                        font-size: 32px
                        line-height: 30px

                > div
                    display: flex

                    @media #{$smartphone-big}
                        flex-wrap: wrap-reverse

                    > div:nth-child(1)
                        flex: 0 0 25%
                        text-align: center
                        width: 25%

                        @media #{$tablet-small}
                            flex-basis: 160px

                        @media #{$smartphone-big}
                            margin-top: 20px
                            flex-basis: 100%

                        > div
                            margin: 0 20px
                            max-width: 180px
                            font-size: 15px

                            @media #{$tablet-small}
                                margin-left: 0
                               
                            @media #{$smartphone-big}
                                display: flex
                                max-width: 100%
                                margin-right: 0px
                                flex-wrap: wrap

                                > div
                                    flex: 0 0 50%

                        .number
                            margin-bottom: 26px

                            > div
                                display: inline-block
                                margin: 0 auto
                                width: 100%
                                max-width: 130px
                                border: 3px solid
                                border-radius: 50%

                                > span
                                    display: block 
                                    position: relative
                                    padding-bottom: 100%
                                
                                    span
                                        position: absolute
                                        left: 50%
                                        top: 50%
                                        transform: translate(-50%, -50%)
                                        font-size: 31px

                            @media #{$smartphone-big}
                                order: 0

                        .composers
                            strong
                                display: block

                        .events 
                            li 
                                a
                                    display: inline-block
                                    -webkit-background-clip: text
                                    -webkit-text-fill-color: transparent
                                    cursor: pointer

                                + li
                                    margin-top: 15px

                            &.less
                                li:nth-child(n+8)
                                    display: none

                            > span
                                display: inline-block
                                margin-top: 40px
                                cursor: pointer

                            @media #{$smartphone-big}
                                order: 3
                        
                        .composers
                            margin-bottom: 26px

                            @media #{$smartphone-big}
                                order: 2

                        .music
                            @media #{$smartphone-big}
                                order: 1

                        div + .music
                            margin-top: 50px

                    > div:nth-child(2)           
                        flex: 1 1 auto

                        .description
                            max-width: 417px

                        .image
                            position: relative
                            margin-bottom: 15px

                            &.portrait
                                max-width: 417px

                            > div
                                background-size: cover
                                background-blend-mode: luminosity 

                            > p
                                position: absolute
                                display: flex
                                width: 400px
                                height: 24px
                                left: -4px
                                top: 376px
                                transform-origin: left bottom
                                transform: rotate(-90deg)
                                font-size: 8px
                                line-height: 10px 
                                align-items: flex-end
                                justify-content: flex-end
                                text-align: right


                + li
                    margin-top: 20px

                    @media #{$tablet}
                         margin-top: -20px

               
