@import 'sass/theme'

$animation: transform .5s ease-out, opacity .5s ease-out
    
.map
    position: relative
    width: 100%
    height: 100vh

    \:global
        .navigation
            position: absolute
            z-index: 10
            left: 0
            top: 34px
            width: 100% 
            max-width: 1020px
            font-family: 'impuls'
            font-variant-ligatures: discretionary-ligatures
            font-weight: 600
            font-size: 31px
            line-height: 30px
            text-align: center
            
            @media (min-width: 1661px)
                position: fixed
                left: 50%
                transform: translateX(-50%)

            @media #{$tablet}
                padding-left: 190px

            @media #{$tablet-small} 
                font-size: 16px
                line-height: 20px
                top: 40px

            span
                display: inline-block
                padding-left: 10px
                padding-right: 10px
                cursor: pointer

                &.previous
                    float: left
                    margin-left: -10px

                &.next
                    float: right
                    margin-right: -10px

                &.disabled
                    opacity: 0.5
                    cursor: default

        .map
            position: relative
            will-change: opacity, transform
            width: 1457px
            height: 1605px
            transition: all 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)
            
            &.test
               transform: scale(0.9)

            > div
                position: relative
                left: 30px
                top: 120px
                width: 1457px
                height: 1605px
                transform-origin: left top        
                transition: $animation
  
            svg
                position: absolute
                width: 1557px
                height: 1705px
                left: -50px
                top: -50px          

                line,
                path
                    fill: none
                    stroke-linecap: round
                    stroke-linejoin: round
                    transition: all .5s ease-out

            .spot 
                position: absolute
                background: $color-primary
                width: 32px
                height: 32px
                border-radius: 50%
                transition: $animation
                visibility: hidden
                opacity: 0

                &.closest:local
                    animation: spot-animation 0.8s infinite

                &.current
                    z-index: 1
                    cursor: pointer
                    visibility: visible
                    opacity: 1

                &:hover
                    z-index: 2
                    
                .active2
                    position: absolute
                    left: 0
                    top: 0
                    width: 32px
                    height: 32px
                    background: #001095
                    border-radius: 50%
                    background: radial-gradient(closest-side, rgba(0, 207, 183, 0.3) 50%, rgba(0, 207, 183, 0.2) 50.1%, rgba(0, 207, 183, 0.2) 75%, rgba(0, 207, 183, 0.1) 75.1%, rgba(0, 207, 183, 0.1) 100% )

                   
                .label
                    position: absolute
                    top: 50px 
                    left: 16px
                    font-weight: 700
                    transform: translateX(-50%)
                    white-space: nowrap

                &.group 
                    .label
                        left: 28px

            &.noanimate 
                > div,
                .spot,
                path,
                line
                    transition-duration: 0s

@keyframes spot-animation
    0%
        background: $color-primary
    50%
        background: $color-primary
    50.01%
        background: none
    100%
        background: none  

.detail
    position: fixed
    z-index: 100
    left: 0
    top: 0
    width: 100%
    height: 100%

    > div 
        position: absolute
       
        left: 50%
        top: 50%
        width: 92.2vw
        max-width: 594px
        
        opacity: 0
        animation: fadeIn 0.5s cubic-bezier(0.190, 1.000, 0.220, 1.000) forwards
        will-change: opacity, transform
        transform: translate(-50%, -50%)

        > div
            position: relative
            padding-bottom: 100%
            overflow: hidden
            border-radius: 50%

    &:global(.out)
        > div
            animation: fadeOut 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000) forwards
    
    \:global
        .image
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%
            background: url(https://impulsneuemusik.org/wp-content/uploads/2020/03/169_Hitchkiker-cencastrable_cmyk-1024x678.jpg)
            background-size: cover
            background-blend-mode: luminosity

            > span 
                position: absolute
                left: 0
                top: 0
                width: 100%
                height: 100%

        a 
            position: absolute
            z-index: 2
            left: 0
            top: 0
            width: 100%
            height: 100%
            border-radius: 50%

        .close 
            position: absolute
            z-index: 3
            left: 50%
            top: 105%
            transform: translateX(-50%)
           
            font-weight: 700
            font-size: 18px
            line-height: 20px
            cursor: pointer
            color: $color-primary

        .text
            position: absolute 
            z-index: 1
            width: 34%
            left: 33%
            bottom: 5%
            color: #fff
            text-align: center
            -webkit-hyphens: none
            hyphens: none
            
            h2,
            p,
            span
                display: block
                font-size: 18px
                line-height: 20px

            h2
                font-weight: 700
                margin-bottom: 40px

@keyframes fadeIn
    0%  
        opacity: 0.01
        transform: translate(-50%, -50%) scale(0.7)

    100%
        opacity: 0.95
        transform: translate(-50%, -50%) scale(1)

@keyframes fadeOut
    0%  
        opacity: 0.95
        transform: translate(-50%, -50%) scale(1)

    100%
        opacity: 0
        transform: translate(-50%, -50%) scale(0.7)