.app
    font-family: 'LotaGrotesqueAlt3'
    font-weight: 300
    font-size: 15px
    line-height: 20px
    color: #0022bf

    \:global
        a
            color: #0022bf

        h1, h2, h3, h4, h5, h6 
            font-weight: normal

        h1 
            font-size: 18px
            line-height: 20px

        h2, h3, h4, h5, h6 
            font-size: 18px
            line-height: 20px

        h2
            margin-top: 55px
            margin-bottom: 20px

        p
            margin: 0

        .center 
           

            &.main
                max-width: 910px

        .color2 
            color: #00c800

        span.fr 
            font-weight: bold

        label
            display: block
            margin: 25px 0 15px 0

        label.small 
            font-size: 12px
            line-height: 15px

        form
            input[type="text"],
            input[type="password"],
            input[type="number"],
            textarea
                width: 100%
                padding: 10px
                line-height: 40px
                border: 2px solid #0022bf
                color: #0022bf
                font-size: 18px
                font-family: 'LotaGrotesqueAlt3'

            input[type="text"],
            input[type="password"],
            input[type="number"]       
                height: 40px

            .small input[type="text"]
                width: 116px

            textarea
                height: 210px
                resize: none
                font-weight: 300
                font-size: 15px
                line-height: 20px

            div.input 
                position: relative

                input + span 
                    position: absolute
                    bottom: 0
                    right: 0
                    line-height: 40px
                    padding: 0px 10px 0px 0
                    pointer-events: none

                + div.input 
                    margin-top: 10px

            div.input.currency

                input 
                    text-align: right
                    padding-right: 20px

            div.input.telephone
                input
                    padding-left: 18px

                ::before
                    position: absolute
                    z-index: 1
                    display: inline-block
                    bottom: 0
                    left: 0
                    line-height: 40px
                    padding: 0px 10px 0px 10px
                    pointer-events: none
                    content: "+"
                    font-size: 18px
                    font-weight: 400
                    font-family: 'LotaGrotesqueAlt3'

            div.input.select
                .selected
                    width: 100%
                    padding: 10px
                    line-height: 20px
                    height: 40px
                    border: 2px solid #0022bf
                    color: #0022bf
                    font-size: 18px
                    font-family: 'LotaGrotesqueAlt3'
                    cursor: pointer


                .select
                    position: absolute
                    z-index: 10
                    top: 100%
                    margin-top: -2px
                    left: 0
                    width: 100%
                    border: 2px solid #0022bf
                    background: #fff

                    > span
                        display: block
                        line-height: 40px
                        font-size: 18px
                        padding-left: 10px
                        cursor: pointer

            .error
                input[type="text"],
                input[type="password"],
                textarea
                    border-color: red

            .button.submit
                display: flex
                align-items: center
                justify-content: center
                flex-direction: column
                height: 50px
                font-size: 12px
                line-height: 15px
                background: #0022bf
                text-align: center
                color: #fff
                cursor: pointer
                border-radius: 25px

                &.disabled
                    opacity: 0.5

            .loading.overlay
                position: fixed
                z-index: 100
                width: 100%
                height: 100%
                left: 0
                top: 0
                background: rgba(255,255,255, 0.8)

                > div
                    position: absolute
                    left: 50%
                    top: 50%
                    transform: translate(-50%, -50%)
                    text-align: center
                    width: 300px
                    height: 300px
                    background: #001095
                    display: flex
                    align-items: center
                    justify-content: center
                    border-radius: 50%
                    flex-direction: column
                    word-break: keep-all
                    padding: 40px

                    p 
                        color: #fff
                        font-size: 15px
                        line-height: 20px

                        + p
                            margin-top: 20px
                        

                        .accept
                            cursor: pointer

        @media print 
            form
                textarea
                    height: 900px
                    font-size: 11px !important
                    line-height: 14px !important

                input[type="text"],
                input[type="number"] 
                    font-size: 11px !important
                    line-height: 14px !important
