.catalogue 
    > div
        position: relative
        width: 100%
        max-width: 490px
        margin: 0 auto
        text-align: center

        \:global
            .image
                background-size: cover

            p
                margin-top: 32px
                font-weight: bold