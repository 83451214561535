.application
    h2
        &:global(.tall)
            font-size: 24px
            line-height: 26px

    \:global
        .logout
            float: right
            margin-top: -55px

        .cols3
            display: flex
            flex-wrap: wrap

            margin: 0 -15px

            > div
                flex: 0 0 33.33%
                padding: 0 15px
                align-self: flex-end

                &.double
                    flex: 0 0 66.66%

        .colsButtons
            display: flex
            margin: 0 -15px

            > div:not(.state)
                flex: 1
                display: flex
                align-items: center
                padding: 10px
                margin: 15px
                font-size: 12px
                line-height: 15px
                background: #0022bf
                text-align: center
                color: #fff
                cursor: pointer
                border-radius: 25px

                &.submit
                    opacity: 0.6

                &.validated
                    opacity: 1

                > div
                    width: 100%

            .state
                .print
                    cursor: pointer

        .react-datepicker-wrapper
            display: block

        .radio,
        .checkbox
            > div
                position: relative
                width: auto
                display: inline-block
                margin-bottom: 10px
                padding-left: 50px
                padding-right: 15px
                cursor: pointer

                .icon
                    position: absolute
                    left: 0
                    top: 0
                    display: block
                    width: 44px
                    height: 44px
                    border: 2px solid #0022bf
                    border-radius: 50%

                    span
                        position: absolute
                        display: block
                        left: 4px
                        top: 4px
                        width: 32px
                        height: 32px
                        border-radius: 50%
                        background-color: #0022bf !important
                        -webkit-print-color-adjust: exact
                        content: ""

                .label
                    line-height: 44px

                    a
                        position: relative
                        z-index: 10

            &.error
                .icon
                    border-color: red

            &.long
                > div
                    display: flex
                    min-height: 44px
                    align-content: center
                    flex-direction: column
                    justify-content: center

                    .label.all
                        line-height: inherit

        .file
            position: relative
            margin-top: 10px
            input
                display: none

            .select
                cursor: pointer

            &.withPreview
                .select
                    margin-top: 15px

            > span
                display: block
                cursor: pointer
                word-break: break-word

                a
                    text-decoration: none
                    font-size: 12px
                    line-height: 15px

            .select,
            .remove
                position: relative
                display: inline-block
                cursor: pointer
                padding: 5px 15px
                color: #fff
                border-radius: 15px
                line-height: 20px
                font-size: 12px

            .remove
                margin-top: 15px

            .preview
                margin-bottom: 5px
                width: 100%
                border: 2px dashed #0022bf
                padding-bottom: 61%
                background-size: contain
                background-position: center center
                background-repeat: no-repeat

        .repeater
            > div + div
                margin-top: 25px

            .add,
            .remove
                position: relative
                display: inline-block
                cursor: pointer
                padding: 5px 15px
                color: #fff
                border-radius: 15px
                line-height: 20px
                font-size: 12px

            .add
                margin-top: 55px
                padding-left: 27px

                &::before
                    display: block
                    position: absolute
                    left: 14px
                    top: 6px
                    content: '+'
                    font-size: 18px

            .remove
                margin-top: 22px

            &.closeAdd
                .add
                    margin-top: 22px

        .textarea
            position: relative

            .counter
                position: absolute
                right: 0
                top: 0

        .finance
            h3
                margin-top: 22px
                margin-bottom: 15px

            .placeholder
                position: absolute
                z-index: 1
                width: 100%
                height: 100%
                color: #AAA
                pointer-events: none
                padding: 2px 40px 2px 10px
                font-size: 13px
                line-height: 1.1
                display: flex
                align-items: center

            .cols
                display: flex
                flex-wrap: wrap
                margin: 0 -15px

                > div
                    padding: 0 15px

                    &:nth-child(1)
                        flex: 0 0 66.72%

                    &:nth-child(2),
                    &:nth-child(3)
                        flex: 0 0 16.64%

            .total
                margin-top: 15px

                > div
                    &:nth-child(2),
                    &:nth-child(3)
                        p
                            padding-right: 10px
                            text-align: right

            .text-fixed
                margin-bottom: 25px
                width: 100%
                padding-top: 8px
                line-height: 20px
                min-height: 40px

                color: #0022bf
                font-size: 18px
                font-family: 'LotaGrotesqueAlt3'

            &.revenue

        .press
            margin-bottom: 20px
            .cols
                display: flex
                flex-wrap: wrap
                margin: 0 -15px

                > div
                    padding: 0 15px

                    &:nth-child(1)
                        flex: 0 0 40%

                    &:nth-child(2)
                        flex: 0 0 20%

                    &:nth-child(3)
                        flex: 0 0 40%

            .row + .row
                margin-top: 10px

        ul
            list-style: none
            padding: 0

            li
                position: relative
                padding-left: 15px

            li:before
                display: block
                position: absolute
                left: 0
                top: 0
                content: '-'
