@import 'sass/theme'

.textImage 
    display: flex
    margin-left: -16px

    @media #{$smartphone-small}
        flex-wrap: wrap

    > div
        flex: 0 0 33.33%
        padding-left: 16px

        + div
            flex: 1

        @media #{$tablet-small}
            flex-basis: 50%

        @media #{$smartphone-small}
            flex-basis: 100%

        &:nth-child(2)
            @media #{$smartphone-small}
                margin-top: 26px
            

    \:global
        .image 
            position: relative
            padding-bottom: 100%
            background-size: cover
            background-position: center
            
            &.colored
                background-blend-mode: luminosity